import { ServerFetch } from '../main-model'

export default class DebitMethodModel extends ServerFetch {
    getDebitMethodBy = (data) => this.authFetch({
        url: 'debit-method/getDebitMethodBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDebitMethodByCode = (data) => this.authFetch({
        url: 'debit-method/getDebitMethodByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}