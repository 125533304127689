import { ServerFetch } from '../main-model'

export default class ReceiptRequestCancelModel extends ServerFetch {
    getReceiptRequestCancelBy = (data) => this.authFetch({
        url: 'receipt-request-cancel/getReceiptRequestCancelBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptRequestCancelByCode = (data) => this.authFetch({
        url: 'receipt-request-cancel/getReceiptRequestCancelByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertReceiptRequestCancel = (data) => this.authFetch({
        url: 'receipt-request-cancel/insertReceiptRequestCancel',
        method: 'POST',
        body: JSON.stringify(data),
    })
}