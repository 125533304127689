import { createContext } from "react"

const authContext = createContext({
  authcertifying: true,
  authenticated: false,
  permissions: [],
  company_info: {},
  station_setting: {},
  user: {},
  _handleLogin: () => { },
  _handleLogout: () => { },
  _initiateAuthentication: () => { },
})

export const AuthProvider = authContext.Provider
export const AuthConsumer = authContext.Consumer