import { ServerFetch } from '../main-model'

export default class ReceiptTemporaryListListModel extends ServerFetch {
    getReceiptTemporaryListBy = (data) => this.authFetch({
        url: 'receipt-temporary-list/getReceiptTemporaryListBy',
        method: 'POST',
        body: JSON.stringify(data),
    }) 
    
    generateReceiptTemporaryListListBy = (data) => this.authFetch({
        url: 'receipt-temporary-list/generateReceiptTemporaryListListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}