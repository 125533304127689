const GROBAL = {
  SERVER: {
    // local ---------------------------------------------------------
    // URL: 'http://localhost:5800/',
    // URL_EXPORT: 'http://localhost:81/rkk_export/',

    // develop -------------------------------------------------------
    // URL: 'https://rvscs-develop.com/rkk-service/',
    // URL_EXPORT: 'http://rkk-export.rvscs.com/', 
    // socket_io_url: 'wss://rvscs-develop.com/',
    // socket_io_path: '/rkk-socket-service/socket.io/',

    // production ----------------------------------------------------
    URL: 'https://rvscs-prod.com/rkk-service/',
    URL_EXPORT: 'https://rkk-export.rvscs-service.com/',
    socket_io_url: 'wss://rvscs-prod.com/',
    socket_io_path: '/rkk-socket-service/socket.io/',
  },

  CLIENT: {
    URL : 'http://rkk-backoffice.rvscs-service.com/#/',
    // URL : 'http://localhost:3000/#/',
  }, 
  

  ACCESS_TOKEN: {
    'x-access-token': localStorage.getItem("x-access-token"),
  },
}

export default GROBAL
