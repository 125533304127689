import React, { Component } from 'react'
import Swal from 'sweetalert2'
import jwt_decode from "jwt-decode"

import { AuthProvider } from '../../contexts/auth.context'

import AuthLoading from './auth-loading'

import GLOBAL from '../../GLOBAL'

import { UserModel, CompanyInfoModel } from '../../models'

const user_model = new UserModel()
const company_model = new CompanyInfoModel()

class Auth extends Component {
  state = {
    authcertifying: true,
    authenticated: false,
    permissions: [],
    company_info: {},
    user: {},
  }

  componentDidMount() {
    this._initiateAuthentication()
  }

  _checkLogin = async ({ user_username, user_password, }) => {
    const res_login = await user_model.checkLogin({ user_username, user_password, })

    const company_info = await company_model.getCompanyInfo()

    if (res_login.require === false) {
      this.setState({ authcertifying: false, }, () => {
        Swal.fire({ title: "ไม่สามารถล็อคอินได้ !", text: 'คำขอเกิดข้อผิดพลาด', icon: "error", })
      })
    } else if (res_login.data.length === 0) {
      this.setState({ authcertifying: false, }, () => {
        Swal.fire({ title: "ไม่สามารถล็อคอินได้ !", text: 'โปรดตรวจสอบชื่อผู้ใช้และรหัสผ่านของคุณ', icon: "warning", })
      })
    } else if(res_login.login_pos !== 1) {
      this.setState({ authcertifying: false, }, () => {
        Swal.fire({ title: "ไม่สามารถล็อคอินได้ !", text: 'ไม่มีสิทธิเข้าสู่ระบบ POS', icon: "warning", })
      })
    } else {
      try {
        localStorage.setItem('x-access-token', res_login.x_access_token)
        localStorage.setItem('session-user', JSON.stringify(res_login.data[0]))

        GLOBAL.ACCESS_TOKEN = { 'x-access-token': res_login.x_access_token }

        const { permissions } = jwt_decode(res_login.permissions_token)

        this.setState({
          authcertifying: false,
          authenticated: true,
          permissions: permissions || [],
          company_info: company_info.data[0],
          user: res_login.data[0],
        })
      } catch (e) {
        this.setState({
          authcertifying: false,
        }, () => {
          console.log('_checkLogin ', e)
        })
      }
    }
  }

  _initiateAuthentication = () => {
    try {
      const session_user = localStorage.getItem('session-user')

      if (session_user) {
        const { user_username, user_password, } = JSON.parse(session_user)

        this._checkLogin({ user_username, user_password, })
      } else {
        this.setState({ authcertifying: false, })
      }
    } catch (e) {
      this.setState({
        authcertifying: false,
      }, () => {
        localStorage.removeItem('x-access-token')
        localStorage.removeItem('session-user')

        console.log('_initiateAuthentication ', e)
      })
    }
  }

  _handleLogin = (data) => !this.state.authcertifying && this.setState({ authcertifying: true, }, () => this._checkLogin(data))

  _handleLogout = () => {
    try {
      localStorage.removeItem('x-access-token')
      localStorage.removeItem('session-user')
      window.location.reload()
    } catch (e) {
      console.log('_handleLogout ', e)
    }
  }

  render() {
    return (
      <AuthProvider
        value={{
          ...this.state,
          _handleLogin: this._handleLogin,
          _handleLogout: this._handleLogout,
          _initiateAuthentication: this._initiateAuthentication,
        }}
      >
        {this.state.authcertifying ? <AuthLoading /> : this.props.children}
      </AuthProvider>
    )
  }
}

export default Auth