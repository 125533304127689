import { ServerFetch } from '../main-model'

export default class ProductReplace extends ServerFetch {
    async getProductReplaceBy(data) {
        return this.authFetch({
            url: 'product-replace/getProductReplaceBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

}