import { ServerFetch } from '../main-model'

export default class ProductGenerationListModel extends ServerFetch {
    async getProductGenerationListLastCode(data) {
        return this.authFetch({
            url: 'product-generation-list/getProductGenerationListLastCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getProductGenerationListBy(data) {
        return this.authFetch({
            url: 'product-generation-list/getProductGenerationListBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getProductGenerationListByCode(data) {
        return this.authFetch({
            url: 'product-generation-list/getProductGenerationListByCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async insertProductGenerationList(data) {
        return this.authFetch({
            url: 'product-generation-list/insertProductGenerationList',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async updateProductGenerationListByCode(data) {
        return this.authFetch({
            url: 'product-generation-list/updateProductGenerationListByCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async deleteProductGenerationListByCode(data) {
        return this.authFetch({
            url: 'product-generation-list/deleteProductGenerationListByCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }
}