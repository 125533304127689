import { ServerFetch } from '../main-model'

export default class ProductCategory extends ServerFetch {
    async getProductCategoryByCode(data) {
        return this.authFetch({
            url: 'product-category/getProductCategoryByCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getProductCategoryBy(data) {
        return this.authFetch({
            url: 'product-category/getProductCategoryBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

}