import { ServerFetch } from '../main-model'

export default class ReceiptListModel extends ServerFetch {
    getReceiptBy = (data) => this.authFetch({
        url: 'receipt/getReceiptBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptListBy = (data) => this.authFetch({
        url: 'receipt-list/getReceiptListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateReceiptListBy = (data) => this.authFetch({
        url: 'receipt-list/generateReceiptListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}