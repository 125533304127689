import { ServerFetch } from '../main-model'

export default class ProductCustomerModel extends ServerFetch {
    getProductCustomerLastCode = (data) => this.authFetch({
        url: 'product-customer/getProductCustomerLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductCustomerBy = (data) => this.authFetch({
        url: 'product-customer/getProductCustomerBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductCustomerByCode = (data) => this.authFetch({
        url: 'product-customer/getProductCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateProductCustomerBy = (data) => this.authFetch({
        url: 'product-customer/updateProductCustomerBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertProductCustomer = (data) => this.authFetch({
        url: 'product-customer/insertProductCustomer',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteProductCustomerByCode = (data) => this.authFetch({
        url: 'product-customer/deleteProductCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductCustomerPriceByCode = (data) => this.authFetch({
        url: 'product-customer/getProductCustomerPriceByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

}