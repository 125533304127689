import { ServerFetch } from '../main-model'

export default class SaleCutOffModel extends ServerFetch {
    generateSaleCutOffLastCode = (data) => this.authFetch({
        url: 'sale-cut-off/generateSaleCutOffLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSaleCutOffBy = (data) => this.authFetch({
        url: 'sale-cut-off/getSaleCutOffBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseFinishSaleCutOff = (data) => this.authFetch({
        url: 'sale-cut-off/getAdviseFinishSaleCutOff',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertSaleCutOff = (data) => this.authFetch({
        url: 'sale-cut-off/insertSaleCutOff',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCurrentSaleCutOff = (data) => this.authFetch({
        url: 'sale-cut-off/getCurrentSaleCutOff',
        method: 'POST',
        body: JSON.stringify(data),
    })

}