import GLOBAL from '../GLOBAL'

class MainModel {
    directEndpointFetch = (endpoint, data) => fetch(endpoint, {
        method: data.method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: data.body,
    }).then((response) => response.json().then((e) => e)).catch((error) => ({ require: false, data: [], error, }))

    authEndpointFetch = async (endpoint, data) => {
        const response = await fetch(endpoint, {
            method: data.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...GLOBAL.ACCESS_TOKEN,
            },
            body: data.body,
        }).then((response) => response.json().then((e) => e)).catch((error) => ({ require: false, data: [], error, }))

        if (response.unauthorized) {
            console.log('unauthorized', response.error)

            localStorage.removeItem('x-access-token')
            localStorage.removeItem('session-user')
            window.location.reload()
        }

        return response
    }
}

export class ServerFetch extends MainModel {
    directFetch = (data) => this.directEndpointFetch(`${GLOBAL.SERVER.URL}${data.url}`, data)
    authFetch = (data) => this.authEndpointFetch(`${GLOBAL.SERVER.URL}${data.url}`, data)
}

// export class AccountFetch extends MainModel {
//     directFetch = (data) => this.directEndpointFetch(`${GLOBAL.ACCOUNT_SERVER.URL}${data.url}`, data)
//     authFetch = (data) => this.authEndpointFetch(`${GLOBAL.ACCOUNT_SERVER.URL}${data.url}`, data)
// }

// export class ServerFetch extends MainModel {
//     directFetch = (data) => this.directEndpointFetch(`${GLOBAL.BASE_SERVER.URL}${data.url}`, data)
//     authFetch = (data) => this.authEndpointFetch(`${GLOBAL.BASE_SERVER.URL}${data.url}`, data)
// }

// export class ServerFetch extends MainModel {
//     directFetch = (data) => this.directEndpointFetch(`${GLOBAL.FINANCE_SERVER.URL}${data.url}`, data)
//     authFetch = (data) => this.authEndpointFetch(`${GLOBAL.FINANCE_SERVER.URL}${data.url}`, data)
// }

// export class PurchaseFetch extends MainModel {
//     directFetch = (data) => this.directEndpointFetch(`${GLOBAL.PURCHASE_SERVER.URL}${data.url}`, data)
//     authFetch = (data) => this.authEndpointFetch(`${GLOBAL.PURCHASE_SERVER.URL}${data.url}`, data)
// }

// export class ServerFetch extends MainModel {
//     directFetch = (data) => this.directEndpointFetch(`${GLOBAL.SALE_SERVER.URL}${data.url}`, data)
//     authFetch = (data) => this.authEndpointFetch(`${GLOBAL.SALE_SERVER.URL}${data.url}`, data)
// }

// export class StockFetch extends MainModel {
//     directFetch = (data) => this.directEndpointFetch(`${GLOBAL.STOCK_SERVER.URL}${data.url}`, data)
//     authFetch = (data) => this.authEndpointFetch(`${GLOBAL.STOCK_SERVER.URL}${data.url}`, data)
// }