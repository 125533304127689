import { ServerFetch } from '../main-model'

export default class StockVerifyCountModel extends ServerFetch { 
    generateStockVerifyCountLastCode = (data) => this.authFetch({
        url: 'stock-verify-count/generateStockVerifyCountLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockVerifyCountBy = (data) => this.authFetch({
        url: 'stock-verify-count/getStockVerifyCountBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockVerifyCountByCode = (data) => this.authFetch({
        url: 'stock-verify-count/getStockVerifyCountByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    approveStockVerifyCountBy = (data) => this.authFetch({
        url: 'stock-verify-count/approveStockVerifyCountBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateStockVerifyCountBy = (data) => this.authFetch({
        url: 'stock-verify-count/updateStockVerifyCountBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertStockVerifyCount = (data) => this.authFetch({
        url: 'stock-verify-count/insertStockVerifyCount',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteStockVerifyCountByCode = (data) => this.authFetch({
        url: 'stock-verify-count/deleteStockVerifyCountByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}