import { ServerFetch } from '../main-model'

export default class ReceiptModel extends ServerFetch {
    generateReceiptLastCode = (data) => this.authFetch({
        url: 'receipt/generateReceiptLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateReceiptVatLastCode = (data) => this.authFetch({
        url: 'receipt/generateReceiptVatLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptBy = (data) => this.authFetch({
        url: 'receipt/getReceiptBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptByCode = (data) => this.authFetch({
        url: 'receipt/getReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptOnPayment = (data) => this.authFetch({
        url: 'receipt/getReceiptOnPayment',
        method: 'POST',
        body: JSON.stringify(data),
    })

    requestCancelReceiptByCode = (data) => this.authFetch({
        url: 'receipt/requestCancelReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    increaseReceiptPrintByCode = (data) => this.authFetch({
        url: 'receipt/increaseReceiptPrintByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateReceiptInPosBy = (data) => this.authFetch({
        url: 'receipt/updateReceiptInPosBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertReceipt = (data) => this.authFetch({
        url: 'receipt/insertReceipt',
        method: 'POST',
        body: JSON.stringify(data),
    })
}