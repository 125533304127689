import { ServerFetch } from '../main-model'

export default class ProductScaleModel extends ServerFetch {
    getProductScaleBy = (data) => this.authFetch({
        url: 'product-scale/getProductScaleBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductScaleByCode = (data) => this.authFetch({
        url: 'product-scale/getProductScaleByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}